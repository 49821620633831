// Routes

"use strict";

import { createRouter, createWebHashHistory, Router } from "vue-router";

// Router
// https://router.vuejs.org/guide/#javascript

const routes = [
    {
        name: "home",
        path: '/',
        component: () => import("@/components/routes/HomePage.vue"),
    },

    {
        name: "about",
        path: '/about',
        component: () => import("@/components/routes/AboutPage.vue"),
    },

    {
        name: "login",
        path: '/login',
        component: () => import("@/components/routes/LoginPage.vue"),
    },

    {
        name: "tfa-login",
        path: '/login/tfa',
        component: () => import("@/components/routes/TwoFactorLoginPage.vue"),
    },

    {
        name: "signup",
        path: '/signup',
        component: () => import("@/components/routes/SignupPage.vue"),
    },
    {
        name: "signup-success",
        path: '/signup/success',
        component: () => import("@/components/routes/SignupSuccessPage.vue"),
    },

    {
        name: "forgot-password",
        path: '/password/forgot',
        component: () => import("@/components/routes/ForgotPasswordPage.vue"),
    },
    {
        name: "reset-password",
        path: '/password/reset/:uid/:token',
        component: () => import("@/components/routes/ResetPasswordPage.vue"),
    },

    {
        name: "verify-email",
        path: '/email/verify/:uid/:token',
        component: () => import("@/components/routes/EmailVerifyPage.vue"),
    },

    {
        name: "admin-users",
        path: '/admin/users',
        component: () => import("@/components/routes/admin/UsersAdministration.vue"),
    },

    {
        name: "admin-users-single",
        path: '/admin/users/:id',
        component: () => import("@/components/routes/admin/UsersAdministrationSingle.vue"),
    },

    {
        name: "admin-payment-methods",
        path: '/admin/payment.methods',
        component: () => import("@/components/routes/admin/PaymentMethodsAdministration.vue"),
    },

    {
        name: "help",
        path: '/help',
        component: () => import("@/components/routes/HelpPage.vue"),
    },

    {
        name: "terms",
        path: '/terms',
        component: () => import("@/components/routes/TermsPage.vue"),
    },

    {
        name: "networks",
        path: '/networks',
        component: () => import("@/components/routes/NetworksPage.vue"),
    },

    {
        name: "network",
        path: '/networks/:id',
        component: () => import("@/components/routes/NetworkPage.vue"),
    },

    {
        name: "tokens",
        path: '/tokens',
        component: () => import("@/components/routes/TokensPage.vue"),
    },

    {
        name: "token",
        path: '/tokens/:id',
        component: () => import("@/components/routes/TokenPage.vue"),
    },

    {
        name: "deployment",
        path: '/deployment/:id',
        component: () => import("@/components/routes/DeploymentPage.vue"),
    },

    {
        name: "nft",
        path: '/nft/:dep/:id',
        component: () => import("@/components/routes/NFTPage.vue"),
    },

    {
        name: "sft",
        path: '/sft/:dep/:id',
        component: () => import("@/components/routes/SFTPage.vue"),
    },

    {
        name: "marketplace",
        path: '/marketplace',
        component: () => import("@/components/routes/MarketplacePage.vue"),
    },

    {
        name: "marketplace-entry",
        path: '/marketplace/:id',
        component: () => import("@/components/routes/MarketplaceEntryPage.vue"),
    },

    {
        name: "p2p-marketplace",
        path: '/p2p-marketplace',
        component: () => import("@/components/routes/P2PMarketplacePage.vue"),
    },

    /* Default */

    {
        path: "/:catchAll(.*)",
        component: () => import("@/components/routes/NotFoundPage.vue"),
    },
];

export function makeApplicationRouter(): Router {
    const router = createRouter({
        // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
        history: createWebHashHistory(),
        routes, // short for `routes: routes`
    })

    return router;
}
