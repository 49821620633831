
import { AppEvents } from "@/control/app-events";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import { useVModel } from "../../utils/vmodel";
import { AppPreferences } from "@/control/app-preferences";

export default defineComponent({
  components: {},
  name: "MenuSideBar",
  emits: ["update:expanded"],
  props: {
    expanded: Boolean,
  },
  setup(props) {
    return {
      expandedStatus: useVModel(props, "expanded"),
    };
  },
  data: function () {
    return {
      page: '',
      isAuthenticated: AuthController.isAuthenticated(),
      isAdmin: AuthController.GlobalAdmin,
      darkTheme: AppPreferences.Theme === "dark",
    };
  },
  methods: {
    updatePage: function () {
      this.page = this.$route ? (this.$route.name) : '';
    },

    onAuthChanged: function () {
      this.isAdmin = AuthController.GlobalAdmin;
    },

    expandSidebar: function () {
      this.$emit("toggle-menu");
    },
    onThemeChanged: function () {
      this.darkTheme = AppPreferences.Theme === "dark";
    },
  },
  mounted: function () {
    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener("auth-status-changed", this.$options.onAuthChangedH);
    this.$options.onThemeChangedH = this.onThemeChanged.bind(this);
    AppEvents.AddEventListener("theme-changed", this.$options.onThemeChangedH);
    this.updatePage();
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("auth-status-changed", this.$options.onAuthChangedH);
    AppEvents.RemoveEventListener("theme-changed", this.$options.onThemeChangedH);
  },
  watch: {
    $route: function () {
      this.updatePage();
    },
  },
});
