
import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  name: "TopBar",
  emits: ['toggle-menu'],
  data: function () {
    return {
      isAuthenticated: AuthController.isAuthenticated(),
      darkTheme: AppPreferences.Theme === "dark",
    };
  },
  methods: {
    openUserSettings: function () {
      AppEvents.Emit("account-settings-open");
    },

    login: function () {
      AuthController.PageToGo = this.$route ? (this.$route.name || "home") : "home";
      AuthController.PageToGoParams = this.$route ? (this.$route.params || {}) : {};
      if (AuthController.isAskingForTwoFactor()) {
        this.$router.push({ name: "tfa-login" });
      } else {
        this.$router.push({ name: "login" });
      }
    },

    logout: function () {
      AppEvents.Emit("logout-open");
    },

    onAuthChanged: function () {
      this.isAuthenticated = AuthController.isAuthenticated();
    },

    onThemeChanged: function () {
      this.darkTheme = AppPreferences.Theme === "dark";
    },
  },
  mounted: function () {
    this.$options.onThemeChangedH = this.onThemeChanged.bind(this);
    AppEvents.AddEventListener("theme-changed", this.$options.onThemeChangedH);
    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthChangedH
    );
    AppEvents.RemoveEventListener("theme-changed", this.$options.onThemeChangedH);
  },
});
