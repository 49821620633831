import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuSideBar = _resolveComponent("MenuSideBar")!
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_AccountSettingsModal = _resolveComponent("AccountSettingsModal")!
  const _component_ChangeLanguageModal = _resolveComponent("ChangeLanguageModal")!
  const _component_ChangeThemeModal = _resolveComponent("ChangeThemeModal")!
  const _component_ChangeUsernameModal = _resolveComponent("ChangeUsernameModal")!
  const _component_ChangeEmailModal = _resolveComponent("ChangeEmailModal")!
  const _component_ChangePasswordModal = _resolveComponent("ChangePasswordModal")!
  const _component_AccountDeleteModal = _resolveComponent("AccountDeleteModal")!
  const _component_DevicesModal = _resolveComponent("DevicesModal")!
  const _component_TwoFactorEnableModal = _resolveComponent("TwoFactorEnableModal")!
  const _component_TwoFactorDisableModal = _resolveComponent("TwoFactorDisableModal")!
  const _component_LogoutModal = _resolveComponent("LogoutModal")!
  const _component_MessageModal = _resolveComponent("MessageModal")!
  const _component_SnackBar = _resolveComponent("SnackBar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["main-layout", {
    'sidebar-hidden': !_ctx.sidebarExpanded,
    'dark-theme': _ctx.isDarkTheme,
    'light-theme': !_ctx.isDarkTheme,
  }])
  }, [
    _createElementVNode("a", {
      href: "#",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.skipMainContent && _ctx.skipMainContent(...args))),
      class: "skip-main-content"
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.$t("Skip to main content")), 1)
    ]),
    _createVNode(_component_MenuSideBar, {
      expanded: _ctx.sidebarExpanded,
      "onUpdate:expanded": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.sidebarExpanded) = $event)),
      onToggleMenu: _ctx.toggleMenu
    }, null, 8, ["expanded", "onToggleMenu"]),
    _createVNode(_component_TopBar),
    _createVNode(_component_router_view),
    _createVNode(_component_AccountSettingsModal, {
      display: _ctx.displayModalAccountSettings,
      "onUpdate:display": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.displayModalAccountSettings) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangeLanguageModal, {
      display: _ctx.displayModalLanguage,
      "onUpdate:display": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.displayModalLanguage) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangeThemeModal, {
      display: _ctx.displayModalTheme,
      "onUpdate:display": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.displayModalTheme) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangeUsernameModal, {
      display: _ctx.displayModalChangeUsername,
      "onUpdate:display": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.displayModalChangeUsername) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangeEmailModal, {
      display: _ctx.displayModalChangeEmail,
      "onUpdate:display": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.displayModalChangeEmail) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangePasswordModal, {
      display: _ctx.displayModalChangePassword,
      "onUpdate:display": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.displayModalChangePassword) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_AccountDeleteModal, {
      display: _ctx.displayModalDeleteAccount,
      "onUpdate:display": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.displayModalDeleteAccount) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_DevicesModal, {
      display: _ctx.displayModalDevices,
      "onUpdate:display": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.displayModalDevices) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_TwoFactorEnableModal, {
      display: _ctx.displayModalTwoFactorAuthEnable,
      "onUpdate:display": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.displayModalTwoFactorAuthEnable) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_TwoFactorDisableModal, {
      display: _ctx.displayModalTwoFactorAuthDisable,
      "onUpdate:display": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.displayModalTwoFactorAuthDisable) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_LogoutModal, {
      display: _ctx.displayModalLogout,
      "onUpdate:display": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.displayModalLogout) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_MessageModal, {
      display: _ctx.displayModalMessage,
      "onUpdate:display": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.displayModalMessage) = $event)),
      title: _ctx.messageModalTitle,
      message: _ctx.messageModalMessage
    }, null, 8, ["display", "title", "message"]),
    _createVNode(_component_SnackBar)
  ], 2))
}