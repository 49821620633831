// Vue entry point

"use strict";
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueApexCharts from "vue3-apexcharts";
import { AppPreferences } from "./control/app-preferences";
import { makeApplicationRouter } from './routes';
import { createApp } from 'vue'
import { i18n } from './i18n'
import App from './App.vue'
import { AuthController } from './control/auth';
import { AppEvents } from './control/app-events';
import { WalletController } from './control/wallet-controller';

// Initialize stuff

AppPreferences.LoadPreferences();
AuthController.Initialize();
WalletController.Initialize();

// Global event handlers

document.addEventListener("drop", function (e) {
    e.preventDefault();
    e.stopPropagation();
});

document.addEventListener("dragover", function (e) {
    e.preventDefault();
    e.stopPropagation();
});

window.addEventListener("message", function(event) {
    if (event.origin === window.origin) {
        if (typeof event.data === "object") {
            if (event.data.type === "certificate-validated") {
                AppEvents.Emit("certificate-validated", event.data.hash + "", event.data.token + "", event.data.subject + "")
            }
        }
    }
});

// Setup App

const app = createApp(App);

app.use(i18n); // Multi-language

app.use(makeApplicationRouter()); // Router

app.use(VueApexCharts) // Charts library

if (process.env.VUE_APP_RECAPTCHA_SITE_ID) {
    app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_ID, loaderOptions: { autoHideBadge: true} }) // Recaptcha
}

app.mount('#app');


// Service worker

if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
    try {
        navigator.serviceWorker.register('/service-worker.js?v=' + (process.env.VUE_APP_MANIFEST_VERSION || "0"), { scope: '/' });
    } catch (ex) {
        console.error(ex);
    }
}